import React from 'react';
import {ContactUs} from "../../sections/ContactUs";
import {H1} from "../../components/NativeComponents";
import {Layout} from "../../containers/Layout";
import {PageState} from "../../containers/PageState";

export default function ContactForm(props: PageState) {
    return <Layout {...props} meta={{title: 'Contact Us'}}>
        <ContactUs TitleComponent={H1}/>
    </Layout>
}
